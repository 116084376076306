import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Kontakt } from "../src/gatsby-theme-docz/components/Kontakt/index.js";
import { Kontaktdaten } from "../src/gatsby-theme-docz/components/Kontaktdaten/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "sprechen-sie-uns-an"
    }}>{`Sprechen Sie uns an.`}</h1>
    <p><strong parentName="p">{`Das Klicken der Bälle bringt Sie zu uns.`}</strong></p>
    <Kontakt mdxType="Kontakt" />
    <p>{`Wenn Sie im Raum `}<strong parentName="p">{`Sachsen`}</strong>{` auf der Suche nach kompetenter Unterstützung im
Bereich der `}<strong parentName="p">{`Haustechnik`}</strong>{` sind, bei denen keine sofortige Hilfe durch
einen Notdienst von Nöten ist, schreiben Sie uns gerne eine Mail. So können Sie uns auf unkomplizierte Art und Weise
Ihre Kontaktdaten und weitere Informationen zu Ihrer Anfrage mitteilen.
Insbesondere wenn es sich um einen Terminwunsch für Arbeiten, ein
Beratungsgespräch zu Planungen und Wünschen im `}<strong parentName="p">{`Sanitär-, Heizung-,
Kältetechnik- `}{`&`}{` Lüftungsbereich`}</strong>{` im Raum Sachsen oder einfach nur
eine Frage handelt: Wir werden Ihre Anfrage schnellstmöglich bearbeiten
und uns dann umgehend bei Ihnen melden. Selbstverständlich werden all
Ihre Daten vertraulich behandelt.`}</p>
    <Kontaktdaten mdxType="Kontaktdaten" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      